(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('ImporterBoardMonitorController', ImporterBoardMonitorController);

        ImporterBoardMonitorController.$inject = ['$scope', '$state', '$mdDialog', 'toastr', 'pagingParams', 'paginationConstants', 'ImporterBoardMonitor', 'ParseLinks', 'ACT_TYPES','Atto'];

    function ImporterBoardMonitorController($scope, $state, $mdDialog, toastr, pagingParams, paginationConstants, ImporterBoardMonitor, ParseLinks, ACT_TYPES, Atto) {
        var vm = this;
        vm.ImporterBoardMonitor = ImporterBoardMonitor;
        vm.actTypes = ACT_TYPES;
        vm.protocolImportDetails = [];
        //Search default
        vm.pagingParams = pagingParams || {};
        vm.protocolSystem = vm.pagingParams.protocolSystem;
        vm.protocolNumber = Number(vm.pagingParams.protocolNumber) === 0 ? null : Number(vm.pagingParams.protocolNumber);
        vm.importType = vm.pagingParams.importType;
        vm.direction = vm.pagingParams.direction;
        vm.errorType = vm.pagingParams.errorType;
        vm.year = vm.pagingParams.year;
        vm.attoNumber = vm.pagingParams.attoNumber;
        vm.legislatura = vm.pagingParams.legislatura;
        
        vm.startProtocolImport = startProtocolImport;

         // query stuff
         vm.predicate = vm.pagingParams.predicate;
         vm.reverse = vm.pagingParams.ascending;
         vm.itemsPerPage = paginationConstants.itemsPerPage;

         vm.loading = false;
         vm.manualImportLoadingMap = {};

         vm.legislature = [];
         Atto.queryLegislature({}, onSuccessLeg, onErrorLeg);
         function onSuccessLeg(data) {
             vm.legislature = data;
         }
         function onErrorLeg(error) {
             console.log('Error loading legisalture ', error);
         }

         loadAll();

         function loadAll() {
             vm.loading = true;
             ImporterBoardMonitor.queryPaged({
                 page: vm.pagingParams.page - 1,
                 sort: sort(),
                 size: vm.itemsPerPage,
                 protocolSystem: vm.protocolSystem,
                 protocolNumber: vm.protocolNumber,
                 importType: vm.importType,
                 direction: vm.direction,
                 errorType: vm.errorType,
                 year: vm.year,
                 attoNumber: vm.attoNumber,
                 legislatura: vm.legislatura
             }, onSuccess, onError);
         }

         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.protocolImportDetails = data;
             vm.page = vm.pagingParams.page;
             vm.loading = false;
         }

         function onError(error) {
             console.log("Failure!", error);
             toastr.error(error.data.message);
             vm.loading = false;
         }

         function sort() {
             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
             if (vm.predicate !== 'id') {
                 result.push('id');
             }
             return result;
         }

        vm.transition = function() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                protocolSystem: vm.protocolSystem,
                protocolNumber: vm.protocolNumber,
                importType: vm.importType,
                direction: vm.direction,
                errorType: vm.errorType,
                year: vm.year,
                attoNumber: vm.attoNumber,
                legislatura: vm.legislatura
            });
        }

        vm.filterTotalDocuments = function(documentArray) {
            return documentArray.filter(function(doc) {
                return (doc["deleted"] == undefined || doc["deleted"] == false);
              });
        }

        vm.filterImportedDocuments = function(documentArray) {
            return documentArray.filter(function(doc) {
                return doc["imported"] == true && (doc["deleted"] == undefined || doc["deleted"] == false);
              });
        }

        vm.alertImportDocument = function(documentArray) {
            return documentArray.filter(function(doc) {
                return doc["imported"] == false && (doc["deleted"] == undefined || doc["deleted"] == false);
              }).length > 0;
        }

        vm.filterTechnicalDocuments = function(documentArray) {
            return documentArray.filter(function(doc) {
                return doc["technical"] == true && (doc["deleted"] == undefined || doc["deleted"] == false);
              });
        }

        function startProtocolImport(index, importDetail) {

            var confirm = $mdDialog.confirm()
                    .title('Sei sicuro di voler procedere con l\'import manuale del protocollo?')
                    .textContent('La procedura ')
                    .ariaLabel('Import Manuale Protocollo')
                    .ok('Conferma')
                    .cancel('Annulla');

                $mdDialog.show(confirm).then(function () {
                    vm.manualImportLoadingMap[importDetail.id] = true;

                    ImporterBoardMonitor.retryImport( importDetail , 
                    function(data, headers) {
                        onSuccessManualImport(index, data)
                    }, function(error) {
                        onErrorManualImport(index, error, importDetail)
                    });
                }, function () {
                    
                });            
            
        }

        function onSuccessManualImport(index, data) {

            vm.protocolImportDetails[index] = data;
            vm.manualImportLoadingMap[data.id] = false;
        }

        function onErrorManualImport(index, error, importDetail) {


            vm.manualImportLoadingMap[importDetail.id] = false;
            console.log("Failure!", error);
            toastr.error("Errore nell'import manuale del protocollo " + importDetail.protocolNumber);
            
        }
        
          
    }
})();